import { useMutation, useQuery, UseQueryResult } from "@tanstack/react-query";
import { IWallet } from "../types/types";
import {
  getWallet,
  updateWallet,
} from "../services/wallet";

export const useWallet = (userID: number): UseQueryResult<IWallet> => {
  return useQuery({
    queryKey: ["wallet", userID], // Add userID to the query key for cache invalidation
    queryFn: () => getWallet(userID), // Pass userID to the API function
  });
};



export const useUpdateWallet = () => {
  return useMutation({
    mutationFn: ({ userID, walletData }: { userID: number; walletData: Partial<IWallet> }) =>
      updateWallet(userID, walletData),
  });
};
