import { Modal, Input, Typography } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { showError } from '../../utils/notification'; // Replace with your error notification utility

const { TextArea } = Input;
const { Text } = Typography;

const ShowCancelContestModal = (confirmAction: (reason: string) => void) => {
  let reason = '';

  Modal.confirm({
    title: 'Cancel Contest',
    icon: <ExclamationCircleOutlined style={{ color: '#ff4d4f' }} />,
    content: (
      <div style={{ marginTop: '8px' }}>
        <Text type="danger">
          Are you sure you want to cancel this contest? This action cannot be
          undone.
        </Text>
        <p style={{ margin: '12px 0 8px' }}>
          Please provide a reason for cancellation:
        </p>
        <TextArea
          placeholder="Reason for cancellation (10-250 characters)"
          rows={4}
          onChange={(e) => {
            reason = e.target.value;
          }}
          style={{
            width: '100%',
            marginTop: '8px',
            fontSize: '14px',
          }}
        />
      </div>
    ),
    okText: 'Submit',
    cancelText: 'Cancel',
    okButtonProps: { danger: true },
    onOk: async () => {
      try {
        const trimmedReason = reason.trim();
        if (!trimmedReason) {
          showError('Error', 'Reason is required to cancel the contest.');
          return;
        }
        if (trimmedReason.length < 10 || trimmedReason.length > 250) {
          showError(
            'Error',
            'Reason must be at least 10 characters and no more than 250 characters.'
          );
          return; // Reject and show error
        }
        confirmAction(trimmedReason);
      } catch (error) {
        console.error('Error during contest cancellation:', error);
      }
    },
  });
};

export default ShowCancelContestModal;
