import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { ReactQueryProvider } from './providers/react-query-provider';
import RefreshTokenProvider from './providers/refresh-token-provider';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ReactQueryProvider>
        <RefreshTokenProvider>
          <App />
        </RefreshTokenProvider>
      </ReactQueryProvider>
    </BrowserRouter>
  </React.StrictMode>
);
