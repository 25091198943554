import { QueryFunctionContext } from '@tanstack/react-query';
import axiosInstance from './axiosInstance';

export const getAddress = async (context: QueryFunctionContext<string[]>) => {
  const [, place_id] = context.queryKey;
  const { data } = await axiosInstance.get(
    `/api/location?place_id=${place_id}`
  );
  return data;
};
