// ParticipantUser.tsx
import React, { useState } from 'react';
import { Avatar, Tooltip, Modal } from 'antd';
import { FaUserCircle } from 'react-icons/fa';

interface ParticipantUserProps {
  user: any; // Assuming `user` is the object representing a participant's user info
}

const TableUser: React.FC<ParticipantUserProps> = ({ user }) => {
  const [isPhotoModalVisible, setIsPhotoModalVisible] = useState(false);
  const [modalPhoto, setModalPhoto] = useState<string>('');

  const handleImageClick = (photo: string) => {
    setModalPhoto(photo);
    setIsPhotoModalVisible(true);
  };

  const handleCancel = () => {
    setModalPhoto('');
    setIsPhotoModalVisible(false);
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
      <Avatar
        size={24}
        style={{ cursor: 'pointer' }}
        src={user?.photo}
        icon={!user?.photo && <FaUserCircle />}
        onClick={() => handleImageClick(user?.photo || '')} // Open modal on click
      />
      <Tooltip title={user?.userName || 'Unknown User'}>
        <span
          style={{
            maxWidth: '150px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {user?.userName || 'Unknown User'}
        </span>
      </Tooltip>

      <Modal
        visible={isPhotoModalVisible}
        footer={null}
        onCancel={handleCancel}
        centered
      >
        <img
          alt="user avatar"
          style={{
            width: '100%',
            height: 'auto',
            maxWidth: '80vw',
            maxHeight: '80vh',
          }}
          src={modalPhoto || 'default_image_url'} // Provide a default image if photo is missing
        />
      </Modal>
    </div>
  );
};

export default TableUser;
