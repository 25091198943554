import React, { useState } from 'react';
import {
  Table,
  Input,
  Button,
  Dropdown,
  Menu,
  Space,
  Select,
  Skeleton,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import { DownOutlined } from '@ant-design/icons';
import {
  useAllUsers,
  useDeleteUser,
  useUpdateUser,
} from '../../hooks/useUsers'; // Assuming these hooks are implemented
import { showSuccess, showError } from '../../utils/notification';
import { IUser } from '../../types/types';
import Loader from '../../components/loader/Loader';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import useDebouncedValue from '../../hooks/useDebouncedValue';
import FullScreenLoader from '../../components/loader/FullScreenLoader';

enum EnumUserStatus {
  active = 'active',
  suspended = 'suspended',
  blocked = 'blocked',
}

export enum UserLevelEnum {
  free = 'free',
  user = 'user',
  admin = 'admin',
}

const ManageUsers: React.FC = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [searchColumn, setSearchColumn] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const [filters, setFilters] = useState<{ [key: string]: any }>({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const debouncedSearch = useDebouncedValue(searchTerm, 1000);

  // Fetch users
  const {
    data: users,
    isLoading,
    isError,
  } = useAllUsers(debouncedSearch, filters, currentPage, pageSize);
  const { mutate: updateUser } = useUpdateUser();
  const { mutate: deleteUser } = useDeleteUser();
  const queryClient = useQueryClient();

  // Dropdown menu actions for user
  const menu = (userID: number) => (
    <Menu>
      <Menu.Item
        key="1"
        onClick={() =>
          navigate(`/dashboard/users/edit`, {
            state: userID,
          })
        }
      >
        Edit
      </Menu.Item>
      <Menu.Item key="2" danger onClick={() => handleDelete(userID)}>
        Delete
      </Menu.Item>
      <Menu.Item
        key="3"
        onClick={() =>
          navigate(`/dashboard/users/details`, {
            state: userID,
          })
        }
      >
        View Details
      </Menu.Item>
    </Menu>
  );

  const handleFilterChange = (field: string, value: any) => {
    setFilters({ ...filters, [field]: value });
  };

  const handleTableChange = (pagination: any) => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const handleDelete = (userID: number) => {
    setLoading(true);
    deleteUser(userID, {
      onSuccess: () => {
        showSuccess('Deleted', `User ${userID} deleted successfully.`);
        queryClient.invalidateQueries({ queryKey: ['all-users'] });
        setLoading(false);
      },
      onError: (error) => {
        showError('Error', `Failed to delete user ${userID}.`);
        console.error(error);
        setLoading(false);
      },
    });
  };

  const handleUserLevelChange = (id: number, userLevel: UserLevelEnum) => {
    setLoading(true);

    updateUser(
      { userID: id, userData: { userLevel } },
      {
        onSuccess: () => {
          showSuccess('Updated', 'User level updated successfully.');
          queryClient.invalidateQueries({ queryKey: ['all-users'] });
          setLoading(false);
        },
        onError: () => {
          showError('Error', 'Failed to update user level.');
          setLoading(false);
        },
      }
    );
  };

  const removeFilter = (key: string) => {
    setFilters((prevFilters) => {
      const { [key]: _, ...rest } = prevFilters;
      return rest;
    });
  };

  const handleStatusChange = (id: number, status: EnumUserStatus) => {
    setLoading(true);

    updateUser(
      { userID: id, userData: { status } },
      {
        onSuccess: () => {
          showSuccess('updated', 'Status updated successfully.');
          queryClient.invalidateQueries({ queryKey: ['all-users'] });
          setLoading(false);
        },
        onError: () => {
          showError('Error', 'Failed to update status.');
          setLoading(false);
        },
      }
    );
  };

  const columns: ColumnsType<IUser> = [
    {
      title: 'User ID',
      dataIndex: 'userID',
      key: 'userID',
      sorter: (a, b) => a.userID - b.userID,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      filterDropdown: ({ confirm, setSelectedKeys, selectedKeys }) => (
        <div className="p-2" style={{ width: 250 }}>
          <Input
            placeholder="Filter email"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys([e.target.value]); // Update selected keys
              setSearchColumn(e.target.value); // Update your local state
            }}
            style={{ display: 'block' }}
          />
          {filters.email && (
            <span style={{ fontSize: '10px' }}>
              <strong>Last searched text</strong> : {filters.email}
            </span>
          )}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: 8,
            }}
          >
            <Button
              type="primary"
              size="small"
              onClick={() => {
                handleFilterChange('email', searchColumn); // Apply the filter
                confirm(); // Close the dropdown and apply filter
              }}
              style={{ width: '48%' }}
            >
              Search
            </Button>
            <Button
              size="small"
              onClick={() => {
                setSelectedKeys([]);
                setSearchColumn('');
                removeFilter('email');
                confirm();
              }}
              style={{ width: '48%' }}
            >
              Reset
            </Button>
          </div>
        </div>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'userName',
      key: 'userName',
      sorter: (a, b) => a.userName.localeCompare(b.userName),
      filterDropdown: ({ confirm, setSelectedKeys, selectedKeys }) => (
        <div className="p-2" style={{ width: 250 }}>
          <Input
            placeholder="Filter userName"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys([e.target.value]); // Update selected keys
              setSearchColumn(e.target.value); // Update your local state
            }}
            style={{ display: 'block' }}
          />
          {filters.userName && (
            <span style={{ fontSize: '10px' }}>
              <strong>Last searched text</strong> : {filters.userName}
            </span>
          )}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: 8,
            }}
          >
            <Button
              type="primary"
              size="small"
              onClick={() => {
                handleFilterChange('userName', searchColumn); // Apply the filter
                confirm(); // Close the dropdown and apply filter
              }}
              style={{ width: '48%' }}
            >
              Search
            </Button>
            <Button
              size="small"
              onClick={() => {
                setSelectedKeys([]);
                setSearchColumn('');
                removeFilter('userName');
                confirm();
              }}
              style={{ width: '48%' }}
            >
              Reset
            </Button>
          </div>
        </div>
      ),
    },

    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',

      render: (status: EnumUserStatus, record) => (
        <Select
          defaultValue={status}
          onChange={(newStatus) =>
            handleStatusChange(record.userID, newStatus as EnumUserStatus)
          }
          className="w-full"
        >
          {Object.values(EnumUserStatus)?.map((status) => (
            <Select.Option key={status} value={status}>
              {status}
            </Select.Option>
          ))}
        </Select>
      ),
      filterDropdown: ({
        confirm,
        setSelectedKeys,
        selectedKeys,
        clearFilters,
      }) => (
        <div className="p-2">
          <Select
            placeholder="Select Role"
            value={selectedKeys[0]}
            onChange={(value: any) => {
              setSelectedKeys(value ? [value] : []);
              if (value) {
                handleFilterChange('status', value);
              } else {
                removeFilter('status');
              }
              confirm();
            }}
            style={{ width: '100%', marginBottom: '8px' }}
            allowClear
          >
            {Object.values(EnumUserStatus)?.map((status) => (
              <Select.Option key={status} value={status}>
                {status.charAt(0).toUpperCase() + status.slice(1)}
              </Select.Option>
            ))}
          </Select>
        </div>
      ),
    },

    {
      title: 'User Level',
      dataIndex: 'userLevel',
      key: 'userLevel',

      render: (userLevel: UserLevelEnum, record) => (
        <Select
          defaultValue={userLevel}
          onChange={(newUserLevel) =>
            handleUserLevelChange(record.userID, newUserLevel as UserLevelEnum)
          }
          className="w-full"
        >
          {Object.values(UserLevelEnum)?.map((level) => (
            <Select.Option key={level} value={level}>
              {level.charAt(0).toUpperCase() + level.slice(1)}{' '}
              {/* Capitalize the first letter */}
            </Select.Option>
          ))}
        </Select>
      ),

      filterDropdown: ({
        confirm,
        setSelectedKeys,
        selectedKeys,
        clearFilters,
      }) => (
        <div className="p-2">
          <Select
            placeholder="Select Role"
            value={selectedKeys[0]}
            onChange={(value: any) => {
              setSelectedKeys(value ? [value] : []);
              if (value) {
                handleFilterChange('userLevel', value);
              } else {
                removeFilter('userLevel');
              }
              confirm();
            }}
            style={{ width: '100%', marginBottom: '8px' }}
            allowClear
          >
            {Object.values(UserLevelEnum)?.map((status) => (
              <Select.Option key={status} value={status}>
                {status.charAt(0).toUpperCase() + status.slice(1)}
              </Select.Option>
            ))}
          </Select>
        </div>
      ),
    },

    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Dropdown overlay={menu(record.userID)} trigger={['click']}>
          <Space>
            Actions <DownOutlined />
          </Space>
        </Dropdown>
      ),
    },
  ];

  if (isError) {
    showError('', 'Error loading users');
    return <div>Error loading users.</div>;
  }

  return (
    <div className="p-4 lg:max-w-[calc(100vw-320px)]">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Manage Users</h1>
        {/* <Button
          type="primary"
          onClick={() => navigate('/dashboard/users/addnew')}
        >
          Add New User
        </Button> */}
      </div>
      <Input
        placeholder="Search users by email or name..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="mb-4"
      />

      <Table
        columns={columns}
        dataSource={users?.usersList || []}
        rowKey="userID"
        loading={isLoading || loading}
        pagination={{
          current: currentPage,
          pageSize,

          total: users?.totalCount,
          onChange: (page, pageSize) =>
            handleTableChange({ current: page, pageSize }),
          showSizeChanger: true,
        }}
        scroll={{ x: 'max-content' }}
      />
    </div>
  );
};

export default ManageUsers;
