import React from 'react';
import lopo from '../../assets/lopo. (2).png';
import { FcGoogle } from 'react-icons/fc';
import { handleGoogleLogin } from '../../hooks/useLogout';

const AdminDashboardLogin = () => {
  return (
    <div className="h-screen bg-gray-100 flex items-center justify-center">
      <div className="bg-white shadow-lg rounded-lg p-8 flex flex-col items-center max-w-sm w-full">
        {/* Logo */}
        <img src={lopo} alt="Lopo Logo" className="w-24 h-24 mb-6" />

        {/* Welcome Message */}
        <h2 className="text-2xl font-bold text-gray-800 mb-4">
          Welcome to Lopo Dashboard
        </h2>

        {/* Note */}
        <p className="text-sm text-gray-500 mb-4">
          Only users with admin role can access this dashboard.
        </p>

        {/* Google Login Button */}
        <button
          onClick={handleGoogleLogin}
          className="flex items-center justify-center w-full border border-gray-300 text-gray-600 hover:bg-gray-100 focus:ring-2 focus:ring-gray-300 rounded-lg py-2"
        >
          <FcGoogle className="mr-2 text-xl" />
          Continue with Google
        </button>
      </div>
    </div>
  );
};

export default AdminDashboardLogin;
