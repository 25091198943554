import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, Input, Button, Select, Divider, Avatar } from 'antd';

import { showSuccess, showError } from '../../utils/notification';
import { useUpdateUser, useUserById } from '../../hooks/useUsers';
import { useUpdateWallet, useWallet } from '../../hooks/useWallet';
import { IWallet } from '../../types/types';
import { FaArrowLeft } from 'react-icons/fa';
import Loader from '../../components/loader/Loader';

const UserDetails: React.FC = () => {
  const location = useLocation();
  const userID = location.state; // Get userID from state
  const navigate = useNavigate();
  const { data: user, isLoading: userLoading } = useUserById(userID!); // Fetch user data
  const { mutate: updateUser } = useUpdateUser();

  const [userLogs, setuserLogs] = useState<any[]>([]);

  const { data: wallet, isLoading: walletLoading } = useWallet(userID!); // Fetch wallet data
  const { mutate: updateWallet } = useUpdateWallet();

  const [userForm] = Form.useForm();
  const [walletForm] = Form.useForm();
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    if (user) {
      userForm.setFieldsValue(user);
      setuserLogs([
        {
          key: 'signUpDate',
          label: 'Sign-Up Date',
          value: user?.signUpDate,
        },
        {
          key: 'deleteDate',
          label: 'Delete Date',
          value: user?.deleteDate,
        },
        {
          key: 'lastActivity',
          label: 'Last Activity',
          value: user?.lastActivity || user?.signUpDate,
        },
        {
          key: 'lastLoginIP',
          label: 'Last Login IP',
          value: user?.lastLoginIP,
        },
        {
          key: 'location',
          label: 'Location (Country / City)',
          value: user?.location,
        },
      ]);
    }
    if (wallet) {
      walletForm.setFieldsValue(wallet);
    }
  }, [user, wallet, userForm, walletForm]);

  const handleUserSave = (values: any) => {
    updateUser(
      { userID, userData: values },
      {
        onSuccess: () => {
          showSuccess('Updated', 'User details updated successfully.');
          setEditing(false);
        },
        onError: () => {
          showError('Error', 'Failed to update user details.');
        },
      }
    );
  };

  const handleWalletSave = (values: any) => {
    updateWallet(
      { userID, walletData: values },
      {
        onSuccess: () => {
          showSuccess(
            'Updated',
            'User financial details updated successfully.'
          );
          setEditing(false);
        },
        onError: () => {
          showError('Error', 'Failed to update user financial details.');
        },
      }
    );
  };

  if (userLoading || walletLoading) return <Loader />;

  return (
    <div className="max-w-3xl mx-auto  p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-bold text-gray-800 mb-6">User Details</h2>

      <div className="mb-4 text-right flex justify-between">
        <button
          className=" left-4 top-6 flex items-center gap-2 text-gray-700 hover:text-gray-900"
          onClick={() => navigate('/dashboard/users')}
        >
          <FaArrowLeft className="text-lg" />
          <span>Back</span>
        </button>

        <Button type="default" onClick={() => setEditing((prev) => !prev)}>
          {editing ? 'Cancel' : 'Edit'}
        </Button>
      </div>
      {/* User Details Form */}
      <Form
        form={userForm}
        layout="vertical"
        onFinish={handleUserSave}
        disabled={!editing}
      >
        <div className="flex justify-center items-center pb-8">
          <Avatar
            size={100}
            src={userForm.getFieldValue('photo') || 'default-image-url'}
            alt="User Photo"
          />
        </div>

        <div className="grid grid-cols-2 gap-4">
          <Form.Item
            className="block text-sm font-medium text-gray-700"
            name="userID"
            label="User ID"
          >
            <Input
              className="mt-2 p-3 w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
              disabled
            />
          </Form.Item>
          <Form.Item
            className="block text-sm font-medium text-gray-700"
            name="googleID"
            label="Google ID"
          >
            <Input
              className="mt-2 p-3 w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
              disabled
            />
          </Form.Item>
          <Form.Item
            className="block text-sm font-medium text-gray-700"
            name="email"
            label="Email"
          >
            <Input className="mt-2 p-3 w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500" />
          </Form.Item>
          <Form.Item
            className="block text-sm font-medium text-gray-700"
            name="firstName"
            label="First Name"
          >
            <Input className="mt-2 p-3 w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500" />
          </Form.Item>
          <Form.Item
            className="block text-sm font-medium text-gray-700"
            name="lastName"
            label="Last Name"
          >
            <Input className="mt-2 p-3 w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500" />
          </Form.Item>

          <Form.Item
            className="block text-sm font-medium text-gray-700"
            name="age"
            label="Age"
          >
            <Input className="mt-2 p-3 w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500" />
          </Form.Item>

          <Form.Item
            className="block text-sm font-medium text-gray-700"
            name="status"
            label="Status"
          >
            <Select>
              <Select.Option value="Active">Active</Select.Option>
              <Select.Option value="Inactive">Inactive</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            className="block text-sm font-medium text-gray-700"
            name="gender"
            label="Gender"
          >
            <Select>
              <Select.Option value="male">Male</Select.Option>
              <Select.Option value="female">Female</Select.Option>
              <Select.Option value="other">Other</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            className="block text-sm font-medium text-gray-700"
            name="userLevel"
            label="User Level"
          >
            <Input className="mt-2 p-3 w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500" />
          </Form.Item>
          <Form.Item
            className="block text-sm font-medium text-gray-700"
            name="phone"
            label="Phone"
          >
            <Input className="mt-2 p-3 w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500" />
          </Form.Item>
          <Form.Item
            className="block text-sm font-medium text-gray-700"
            name="publicProfile"
            label="Public Profile URL"
          >
            <Input
              className="mt-2 p-3 w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
              disabled
              placeholder="Not implemented yet"
            />
          </Form.Item>
        </div>
        <Button type="primary" htmlType="submit">
          Save User Details
        </Button>
      </Form>

      {/* Wallet Details Form */}
      <Form
        form={walletForm}
        layout="vertical"
        onFinish={handleWalletSave}
        disabled={!editing}
        className="mt-8"
      >
        <Divider>User Financials</Divider>
        <div className="grid grid-cols-2 gap-4 ">
          <Form.Item
            className="block text-sm font-medium text-gray-700"
            name="walletID"
            label="Wallet ID"
          >
            <Input
              className="mt-2 p-3 w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
              disabled
            />
          </Form.Item>
          <Form.Item
            className="block text-sm font-medium text-gray-700"
            name="credits"
            label="Credits"
          >
            <Input className="mt-2 p-3 w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500" />
          </Form.Item>
          <Form.Item
            className="block text-sm font-medium text-gray-700"
            name="creditsSpent"
            label="Credits Spent"
          >
            <Input className="mt-2 p-3 w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500" />
          </Form.Item>
          <Form.Item
            className="block text-sm font-medium text-gray-700"
            name="payoutBank"
            label="Payout Bank"
          >
            <Input className="mt-2 p-3 w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500" />
          </Form.Item>
          <Form.Item
            className="block text-sm font-medium text-gray-700"
            name="payoutAccount"
            label="Payout Account"
          >
            <Input className="mt-2 p-3 w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500" />
          </Form.Item>

          <Form.Item
            className="block text-sm font-medium text-gray-700"
            name="payoutDetails"
            label="Payout Details"
          >
            <Input className="mt-2 p-3 w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500" />
          </Form.Item>
        </div>

        <Button type="primary" htmlType="submit">
          Save Financial Details
        </Button>
      </Form>

      <Divider className="pt-4">User Address</Divider>
      <div className="grid grid-cols-2 gap-4 text-left pb-4">
        <div className="">
          <label className="block text-sm font-medium text-gray-700">
            Address
          </label>
          <Input
            className="mt-2 p-3 w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
            disabled
            value={user?.address || 'N/A'} // Show "N/A" when the value is empty or null
          />
        </div>
        <div className="">
          <label className="block text-sm font-medium text-gray-700">
            Shipping Address
          </label>

          <Input
            className="mt-2 p-3 w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
            disabled
            value={user?.shippingAddress || 'N/A'} // Show "N/A" when the value is empty or null
          />
        </div>
      </div>

      {/* Logs */}
      <Divider>Logs</Divider>

      <div className="grid grid-cols-2 gap-4 text-left">
        {userLogs.map((log) => (
          <div key={log.key}>
            <label className="block text-sm font-medium text-gray-700">
              {log.label}
            </label>
            <Input
              className="mt-2 p-3 w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
              disabled
              value={
                log.key === 'signUpDate' || log.key === 'lastActivity'
                  ? new Date(log.value).toLocaleDateString('en-GB') // Format the date
                  : log.value || 'N/A'
              } // Show "N/A" when the value is empty or null
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default UserDetails;
